.container.contact__container{
    width:35%; /*
    width:58%; 
    display:grid;
    grid-template-columns: 30% 58%;
    padding-top:5%; */
    gap:12%;

}

#contact {
    background-image: url(../../../src/assets/background_sky.webp);
    margin-top: 0;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative; /* Ensure the pseudo-element is positioned relative to this container */
    overflow: hidden;
}

#contact::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: 1; /* Ensure the overlay is above the background but below the content */
    pointer-events: none; /* Allow interaction with other elements inside the container */
}

#contact * {
    position: relative; /* Ensure content inside stays above the overlay */
    z-index: 2; /* Place the content above the overlay */
}



h1{
display: flex;
justify-content: center;

}

.contact__options{
    display:flex;
    flex-direction: column;
    gap:1.2rem;
    
}

.contact__option{

    background-color:rgba(0, 0, 0, 0.712);
    padding: 1.2rem;
    border-radius:1.2rem;
    text-align:center;
    border:1px solid transparent;
    transition: var(--transition);
    
}

.contact__option:hover{
    background-color:rgba(0, 0, 0, 0.76);
    border-color:black;
    scale:1.1;
}

.contact__option-icon{
    font-size:1.5rem;
    margin-bottom:0.5rem;

}
    
.contact__option a{
    margin-top:0.7rem;
    display:inline-block;
    font-size:0.8rem;
}


/* FORM */
form{
    display:flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width:100%;
    padding: 1.5rem;
    font-size:1.05rem;
    border-radius: 0.5rem;
    background-color:rgba(0, 0, 0, 0.712);
    border: 1px solid rgba(0,0,0,0.712);
    resize:none;
    color:var(--color-white);
    font-family: 'Oswald';
    font-weight:300;
}


 /* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
 @media screen and (max-width: 1024px) {
    .container.contact__container{
        width:70%;
  }
  .contact__options{
    flex-direction: column;
    scale:.9;
}


.contact__option:hover{
    scale:1.1;
}

.contact__option-icon{
    font-size:1.5rem;
    margin-bottom:0rem;

}
    
.contact__option a{
    margin-top:0rem;
    font-size:0.6rem;
}
.titrecontact{
    font-size:2rem;
    margin:0;
    padding:0;
    }
#contact h2{
    font-size:1rem;
    padding:0;
}

}
  /* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
  @media screen and (max-width: 600px) {
    .container.contact__container{
        width:70%;
  }
  .contact__options{
    flex-direction: column;
    scale:.9;
}


.contact__option:hover{
    scale:1.05;
}

.contact__option-icon{
    font-size:1.5rem;
    margin-bottom:0rem;

}
    
.contact__option a{
    margin-top:0rem;
    font-size:0.6rem;
}
.titrecontact{
    font-size:1.8rem;
    margin:0;
    padding:0;
    margin-bottom:-2rem;
    
    }
#contact h2{
    font-size:1rem;
    padding:0;
}

#contact {
    background-size:auto;
    background-size:60rem;
}
.contact__options {
    margin-top: -2rem; /* Réduit l'espace au-dessus */
}
}