* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
    min-height: calc(var(--vh, 1vh) * 100); /* Ensures full viewport height on portrait mode */
    padding-top: 8%;
    overflow: hidden;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
  /* Playlist Controls */
  .playlist-controls {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
    margin-top:5rem;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .playlist-controls.hidden {
    opacity: 0;
    pointer-events: none; /* Prevent interactions when hidden */
  }

  .playlist-button {
    padding: 8px 12px;
    font-size: 0.9rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.6s ease-out;
    width: 100px; /* Set fixed width for consistent size */
    text-align: center; /* Center text horizontally */
}

.playlist-button:hover {
    transform: scale(1.1);
    background-color: #45a049;
    box-shadow: 0px 4px 15px rgba(0, 255, 0, 0.6);
}

  .header__container{
      text-align: center;
      height: 100%;
      position: relative;
      
  }
  .musicplaying {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center; /* Ensures all items are centered */
    flex-direction: column; /* Stack the items vertically */
  }

  .volume-slider {
    -webkit-appearance: none; /* Remove default styles on Webkit browsers */
    appearance: none; /* Remove default styles on other browsers */
    position: absolute;
    left: -90px;
    transform: rotate(-90deg); /* Make slider vertical */
    transform-origin: center;
    width: 100px; /* Length of the slider */
    padding:1rem;
    height: 10px; /* Thickness of the slider track */
    background: linear-gradient(to right, #475e2f96 0%, #5173359d 100%); /* Default gradient track */
    cursor: pointer;
    border-radius:5rem;
    border-color:rgb(21, 66, 21);
  }

  .volume-slider:focus {
    outline: none; /* Remove focus outline */
  }

  /* Webkit Browsers (Chrome, Safari, Edge) */
  .volume-slider::-webkit-slider-runnable-track {
    width: 100%; /* Full width of the slider */
    height: 10px; /* Match the height of the track */
    background: linear-gradient(to right, #6e9b48 0%, #acd071 100%); /* Gradient for visual effect */
    border-radius: 5px;
    border: none;
  }

  .volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default thumb styles */
    appearance: none;
    width: 10px;
    height: 10px;
    background: #ffffff; /* Thumb color */
    border-radius: 50%; /* Circular thumb */
    cursor: pointer;
    position: relative; /* Keeps thumb aligned with track */
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); /* Optional shadow for thumb */
  }

  /* Firefox Browsers */
  .volume-slider::-moz-range-track {
    width: 100%; /* Full width of the slider */
    height: 10px; /* Match the height of the track */
    background: linear-gradient(to right, #6e9b48 0%, #acd071 100%);
    border-radius: 5px;
    border: none;
  }

  .volume-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
  }

  /* IE and Edge (Old versions) */
  .volume-slider::-ms-track {
    width: 100%;
    height: 6px;
    background: transparent; /* Remove default styles */
    border-color: transparent;
    color: transparent;
  }

  .volume-slider::-ms-fill-lower {
    background: #4caf50;
    border-radius: 5px;
  }

  .volume-slider::-ms-fill-upper {
    background: #ddd;
    border-radius: 5px;
  }

  .volume-slider::-ms-thumb {
    width: 12px;
    height: 12px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
  }
  
  /* Playlist Controls */
  .playlist-controls {
    display: flex;
    gap: 8px;
    margin-top: .5rem;
    justify-content: center;
  }
  
  /* Playlist Buttons */
  .playlist-button {
    
    font-family: 'Oswald', sans-serif;
    font-size: 0.9rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.15s ease-out, background-color 0.2s ease, box-shadow 0.25s ease-out; /* Smooth transitions */
  }
  
  /* Hover effect: Slightly scale up the button */
  .playlist-button:hover {
    transform: scale(1.1); /* Scale the button by 10% */
    background-color: #93BA60;
    box-shadow: 0px 4px 6px rgba(62, 88, 43,.6); /* Optional glowing effect on hover */
  }

  h5{ 
      font-size: 1.4rem;
      
      user-select: none;
  }

  a{
      color:var(--color-bg);
  }
/* Default GIF styling (no animation by default) */
.gif {
  border-radius: 12rem;
  background-color: rgba(0, 66, 9, 0.171);
  box-shadow: 0px 0px 15px 5px rgba(110, 196, 93, 0.8); /* Initial shadow */
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

/* Add hover effect for when music is not playing */
.gif:hover {
  background-color: rgba(0, 200, 50, 0.3);
  transform: scale(1.05);
}

/* Apply rotating box shadow when music is playing */
.gif.music-playing {
  animation: rotateShadow 5s linear infinite; /* Shadow rotation animation */
}

/* Keyframes for rotating box shadow */
@keyframes rotateShadow {
  0% {
    
    box-shadow: 0px 0px 5px 7px rgba(110, 196, 93, 1); /* Initial shadow */
  }
  40% {
    box-shadow: 0px 10px 15px 5px rgba(77, 141, 64, 0.8);
  }
  60% {
    box-shadow: 0px 20px 15px 2px rgba(51, 92, 43, 0.8);
  }
  80% {
    box-shadow: 0px 0px 15px 3px rgba(99, 182, 83, 0.8);
  }
  100% {
    
    box-shadow: 0px 0px 5px 7px rgba(110, 196, 93, 1); /* Initial shadow */
  }
}

  /* ============ CTA ========== */

  .cta{
      margin-top:2,5rem;
      flex-direction:flex;
      gap:  1.2rem;
      justify-content: center;

  }
  /* ============ HEADER SOCIALS ========== */

  .header__socials {
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content:center;
      gap: 1.5rem;
      margin-top:.5rem;
      left:0;
      bottom:2rem;
      font-size:1.8rem;
  }

  .socials{
      color:rgb(144, 230, 148);
  }
  h1{
      font-size: 4.4rem;
      user-select: none;
      display: inline-block; /* Make it an inline-block element */
      transition: font-size 0.3s ease; /* Add a smooth transition */
  }

  /* ============ PHOTO MOI ========== */
  .me{
  /*   background: linear-gradient(var(--color-primary),transparent); */
      background:linear-gradient(var(whitesmoke,grey),transparent);
      width:12rem;
      height:12rem;
      position:absolute;
      left:50%;
      transform: translateX(-50%);
      margin-top:0.5rem;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      
      user-select: none;
      
  }

  .musicplaying::after {
    /*content: "<< Cliquez pour jouer une musique!";
    position: absolute;
    left:14.5rem;
    top:4.35em;
    background-color: rgba(105, 103, 103, 0.4);
    transform: translateX(5rem); /* Start 2rem to the right */
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 1);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  .musicplaying:hover::after {
    opacity: 1;
    transform: translateX(0);
  }


  /* ============ ANIMATION TEXTE COULEUR ========== */
  h5{
          background:linear-gradient(
              #04fd00 16.7%,
              #00fe55 16.7%,
              #00fe55 33.4%,
              #00fe48 33.4%,
              #00fe48 50.1%,
              #3edf4d 50.1%,
              #3edf4d 66.8%,
              #74ff33 66.8%,
              #74ff33 83.5%,
              #50b702 83.5%
          );
          -webkit-text-stroke: 0.01em #515151;
          -webkit-background-clip: text;
          background-clip:text;
          line-height:1.1em;
          animation:rainbow 50s linear infinite;
  }

  @keyframes rainbow{
      100%{background-position: 0 30em;}
  }
.DownloadIcon{
  scale:1.3;
  margin-right:5%;
}
  /* Bouton CV */
  .btnCV{
      background-color: rgb(0, 0, 0);
      font-size: 1.2rem;
      color:whitesmoke;
      display: flex;
      align-items: center;
      justify-content: center;
      height:3rem;
      margin-top:2rem;
      margin-bottom:2rem;
      border-radius: 1rem;
      box-shadow: 0px 3px .1rem 5px;
      
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    overflow: hidden;
  }
  .btnCV {
      background: 
      linear-gradient(rgb(122, 202, 122, 0.5), rgb(95, 95, 95, 0.6), rgba(50, 50, 50)) no-repeat
      var(--p, 200%) 100% / 200% var(--p, .08em);
    transition: .1s var(--t, 0s), background-position .1s calc(.1s - var(--t, 0s));


  }

  .btnCV:hover{
      --p: 100%;
      --t: .0s;
      font-size:1.3em;
      color:rgb(176, 243, 167);
      
      box-shadow: 0px 3px .1rem 6px;
  }

  .socials:hover{
      scale:1.3;
      transition:scale .25s;
    

  }

  .socials{
      
      transition:scale .25s;
  }
  .gif{
      border-radius: 12rem;
  background-color: rgba(0, 66, 9, 0.171);
  box-shadow: 5px 0px 0rem rgb(110, 196, 93);
  }



  /* PLAYER MUSIQUE */
  btn.zik{
      background-color: rgb(103, 192, 100);
      border-radius: 5rem;
      padding:1%;
      display: flex;
      justify-content: right;
      align-items: right;
      width:3%;
      margin-left:5rem;
      cursor:pointer;
  }


  btn.zik:hover{
      background-color: #1ee460;
      scale:1.1;
  }


  /* SCROLL BUTTON */
  #section-1 {
    background-color: #00ff40;
    padding:0;
  }
  a#scroll-btn {
    position: absolute;
    height: 10em;
    width: 6.25em;
    border: 0.5em solid #ffffff7c;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 3em;
    scale:0.6;
    transition: scale 0.3s ease;
  }
  a#scroll-btn:before {
    position: absolute;
    content: "";
    margin: auto;
    left: 0;
    right: 0;
    height: 1.2em;
    width: 1.2em;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    animation: move-down 2s infinite;
    
  }
  @media (max-height: 799px) { /* Correspond à une hauteur réduite */
    header {
      min-height: calc(var(--vh, 1vh) * 100); /* Réduit pour tenir compte de la barre */
      padding-top: 0%;
    }
    .header__container {
      padding: 0% 0; /* Ajuste le contenu pour éviter qu'il ne soit trop serré */
    }
  }
  a#scroll-btn:hover {
    scale:0.65;
  }


  @keyframes move-down {
    0% {
      opacity: 1;
    }
    100% {
      transform: translateY(5.3em);
      opacity: 0.8;
    }
  }
  a#scroll-btn:after {
    position: absolute;
    content: "";
    width: 12em;
    display: block;
    width: 12em;
    text-align: center;
    left: -4.2em;
    bottom: -2.5em;
    font-size: 1.3em;
    color: white;
    letter-spacing: 3px;
    animation: opacity 2s infinite;
  }
  @keyframes opacity {
      0% {
        opacity:1;
      }
      50% {
        opacity:0.5;
      }
      100% {
          opacity:1;
        }
    }


  /* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
  @media screen and (max-height:675px) {

    header {
      height: auto;
      min-height: 140vh; /* Ensures full viewport height on portrait mode */
      padding-top: 5%;
      overflow: hidden;
  }
  }


  @media screen and (max-width: 1024px) {

    header {
      height: auto;
      min-height: 100vh; /* Ensures full viewport height on portrait mode */
      padding-top: 5%;
      overflow: hidden;
  }
  .me{

    width:12rem;
  }

    h1{
      font-size:2.8rem;
    }

    h5{
      font-size:1.1rem;
    }
    
    a#scroll-btn {
      width: 6em;
      scale:0.6;
      transition: scale 0.3s ease;
    }
    a#scroll-btn:before {
      position: absolute;
      content: "";
      margin: auto;
      left: 0;
      right: 0;
      top: 1.2em;
      height: 1.2em;
      width: 1.2em;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      animation: move-down 2s infinite;
    }
  
    a#scroll-btn:hover {
      scale:0.45;
      
    }
    .btnCV{
      font-size: 1.1rem;
      height:2.25rem;
      margin-top:1rem;
      margin-bottom:0rem;
      
  }
  }

  @media screen and (max-aspect-ratio: 1/1) { /* Landscape modes */
    header {
        height: auto;
        min-height: 100vh; /* Fallback for rotated devices */
        padding: 5% 0; /* Adjust spacing */
    }
    body, html {
      margin: 0;
      padding: 0;
      overflow-x: hidden; /* Prevent horizontal scroll */
      
  }
  
}
    /* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
    @media screen and (max-width: 600px) {

      header {
        height: auto;
        min-height: 90vh; /* Ensures full viewport height on portrait mode */
        padding-top: 25%;
        overflow: hidden;
    }
    .me{

      width:10rem;
    }

      h1{
        font-size:2.8rem;
      }

      h5{
        font-size:1.1rem;
      }
      
      a#scroll-btn {
        width: 6em;
        scale:0.4;
        transition: scale 0.3s ease;
      }
      a#scroll-btn:before {
        position: absolute;
        content: "";
        margin: auto;
        left: 0;
        right: 0;
        top: 1.2em;
        height: 1.2em;
        width: 1.2em;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        animation: move-down 2s infinite;
      }
    
      a#scroll-btn:hover {
        scale:0.45;
        
      }
      .btnCV{
        font-size: 1.1rem;
        height:2.25rem;
        margin-top:1rem;
        margin-bottom:0rem;
        
    }
    }
