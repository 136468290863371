#portfolioMAIN{
    width: auto;
    box-sizing:border-box;
}

/*    ---    BOUTON PORTFOLIO    --    */
/*.portfolioMAIN__container{ 
    display:flex;
    justify-content:center;
    box-sizing:border-box;
}
.btnportfolio_parent{ 
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnporfolio{ 
    display: flex;
    margin-top:3rem;
    font-size:2rem;
    border-radius:2em;
    background-color: rgba(0, 0, 0, 0.562);
    width:60%;
    height:5rem;
}

.btnporfolio:hover{
    
    background-color: rgba(0, 0, 0, 0.82);
    font-size:2.2rem;
}
    .category-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    gap: 10px;
}

/* Individual Category Button */
.category-button {
    font-family: 'Oswald', sans-serif;
    background-color: rgba(0, 0, 0, 0.562);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom:.5rem;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, font-size 0.3s ease;
    margin-left:.25rem;
    margin-right:.25rem;
    margin-top:.5rem;
}
.category-button > * {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
}

.category-buttons {
    display: flex; /* Enable flexbox */
    justify-content: center; /* Vertically center the container content */
    align-items: center; /* Horizontally center the container content */

}
/* Hover Effect for Buttons */
.category-button:hover {
    background-color: rgba(0, 0, 0, 0.82);
    /*font-size: 1.11rem; */
}

/* Active Button Style */
.category-button.active {
    background-color: rgba(50, 205, 50, 0.8); /* Light Green */
    color: #fff;
    font-weight:300;
    font-size: 1.2rem;
}

/*  ---   PRESENTATION EN HAUT   ---*/

/*    ---    MISE EN FORME TEXTE EN HAUT   --    */
/* "Portfolio" */
.test h3{
    padding-top:2rem;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:2.9rem;
}
/* "Postproduction" (old)/
.test h4{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:2.4rem;
}
/* "Doublage et spots" (old)/
.test h5{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:2.5rem;   
    margin-bottom: 4rem;
}


/* BACKGROUND */
.test {
    background-image: url(../../../src/assets/background_competences.webp); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    z-index: 0;
    position: relative; /* Ensure the ::after pseudo-element is positioned correctly */
}

/* Adding the opacity filter with an ::after pseudo-element */
.test::after {
    content: ''; /* Empty content for the pseudo-element */
    position: absolute; /* Absolute positioning to cover the entire element */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: -1; /* Position it behind the content */
}
/* GRID */
.test-content{
    display:grid;
    grid-template-columns: repeat(5,1fr);

   }
  .test-content.expanded {
  grid-template-columns: repeat(1, 1fr);
}

.test-content.category-grid {
    grid-template-columns: repeat(4, 1fr); /* 3 columns */
    grid-auto-rows: 1fr; /* Ensure uniform row heights */
}

/* Conteneur principal */
.overlay-image-portfolio {
    position: relative;
    width: 100%;
   }
   
   /* Image originale */
   .overlay-image-portfolio .image-portfolio {
    width: 100%;
    height: auto;
   }
   
   /* Texte original */
   .overlay-image-portfolio .text {
    color: #fff;
    line-height: 1.5em;
    text-shadow: 2px 2px 2px #000;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
   }


.overlay-image-portfolio:hover{
   scale:0.95;
   transition:scale .125s;
       box-shadow: 0rem 0rem 1rem rgb(211, 181, 181);
}

.overlay-image-portfolio{
    transition:scale .25s;
}

/********* Hover image et texte *******/

/* Overlay */
.overlay-image-portfolio .hover {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .125s ease;
    background-image: linear-gradient(90deg, rgba(0,0,0,0.835171568627451) 0%, rgba(0,0,0,0.7315301120448179) 80%, rgba(0,0,0,0) 100%);
   }
   
   /* Apparition overlay sur passage souris */
   .overlay-image-portfolio:hover .hover {
    opacity: 1;
   }

   .overlay-image-portfolio:nth-child(1) {
    background-image: linear-gradient(90deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0.6) 80%, rgba(0, 0, 255, 0));
  }
  
  .overlay-image-portfolio:nth-child(2) {
    background-image: linear-gradient(90deg, rgba(255, 255, 0, 0.8), rgba(255, 255, 0, 0.6) 80%, rgba(255, 255, 0, 0));
  }

   
/* MIS EN FORME TEXTE DU HOVER */
.overlay-image-portfolio .hover .text{
    text-align: center;
    justify-content: center;
    color:white;
    font-size:larger;
}


/* Titre */
.overlay-image-portfolio .hover .text p{
    color:rgb(238, 236, 144);
    text-shadow: 1px 1px 2px rgb(72, 72, 72);
}

/* Saison X */
.overlay-image-portfolio .hover .text h4{
    font-size:2.3rem;
    color:rgb(255, 255, 255);
    
}

/* Hover en fonction de la catégorie */




 /* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
 @media screen and (max-width: 1024px) {
    .portfolioMAIN__container .test{
        width:100%;
    }

    .hoversp{

        padding-bottom:4%;
    
        
    }

 

    .test-content{
        display:grid;
        padding:1rem;
        justify-content: center; /* Center items horizontally */
       }

 }
  /* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
  @media screen and (max-width: 600px) {

    
    .test-content{
        display:grid;
        grid-template-columns: repeat(1,1fr);
        padding:0rem;
        
       }

      .test-content.expanded {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .test-content.category-grid {
        grid-template-columns: repeat(2, 1fr); /* 3 columns */
        grid-auto-rows: 1fr; /* Ensure uniform row heights */
    }
    
       
       .hoversp{

        padding-bottom:3.2%;
    
        
    }

    .category-button {
        padding: 5px 10px;
        border-radius: 10px;
        margin-bottom:.5rem;
        font-size: .85rem;
        cursor: pointer;
        transition: background-color 0.3s ease, font-size 0.3s ease;
        margin-left:.25rem;
        margin-right:.25rem;
        margin-top:0rem;
    }


/* MIS EN FORME TEXTE DU HOVER */
.overlay-image-portfolio .hover .text{
    font-size:.5rem;
}

/* Saison X */
.overlay-image-portfolio .hover .text h4{
    font-size:1.2rem;
    color:rgb(255, 255, 255);
    
}
.test h3{
    padding-top:.5rem;
    font-size:2.15rem;
}
.category-button.active {
    font-size: 1rem;
}


}


@keyframes rainbow{
    100%{background-position: 0 20em;}
    }





h4{
    background:linear-gradient(
 
        #74ff33 66.8%,
        #74ff33 83.5%,
        #50b702 83.5%
    );
    -webkit-text-stroke: 0.015em #515151;
    -webkit-background-clip: text;
    background-clip:text;
    line-height:1.1em;
    animation:rainbow 8s linear infinite;
}