#about{
    
}

.about__container {
    display: grid;
    grid-template-columns: 40% 60%;
    height: auto;
    min-height:100vh;
    line-height: 1.7;
    background-image: url(../../../src/assets/background_forest.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    width: 100%;
    position: relative; /* Ensure the pseudo-element is positioned relative to this container */
    overflow: hidden;
}

.about__container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: 1; /* Make sure the dark layer is above the background but below the content */
    pointer-events: none; /* Allow interaction with other elements inside the container */
}

.about__container * {
    position: relative; /* Ensure content inside stays above the overlay */
    z-index: 2; /* Place the content above the overlay */
}



/* PARTIE AVEC PHOTO MOI */
.about__me{
    max-width:100%;
    display:grid;
    place-items:center;
    justify-items:center;
    background-color:rgba(0, 0, 0, 0.082);
    

}
/* TAILLE DE LA PHOTO ET CARACTERISTIQUES */
.about__me-image{
    overflow:hidden;
    max-width:26rem;
    border-radius:1em;
    box-shadow:  5px 5px 5px black;
    margin:0;
    padding: 0;
    margin-top:2rem;
    
    user-select: none;
transition: transform 0.4s ease, border-radius 0.4s ease, box-shadow 0.4s ease;
}

.about__me-image:hover {
    transform: scale(1.05) rotate(.8deg); /* Slight zoom and rotation */
    border-radius: 2em;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.9); /* More pronounced shadow */
}
/* Section A PROPOS (droite) */
.about__content{
    background-color:rgba(0, 0, 0, 0.082);
    color: whitesmoke;
    padding-left:5rem;
    padding-right:5rem;
    padding-top: 2rem;
    font-size:1.3rem;
    word-spacing:0.3rem;  
    padding-bottom:2rem;
    text-align: justify;
    text-justify: inter-word;   
    
}
.about__content h2{
    color:rgb(115, 224, 121);
    font-style:bold;
    font-family: 'Lovelo Black', sans-serif;
}


.logiciels{
    width:65%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding-right:13em; 
    background-color:rgba(0, 0, 0, 0.712);
    padding:10px;
    border-radius: 10rem;
    
    height:85%;
    transition:scale 1s ease-out;
    user-select: none;
    margin-bottom:0;
    margin-top:1rem;  }

/* Mise en forme de "Logiciels" */
.marquee-itemform2{
    transition:scale 1s ease-out;
    width:70%;
    margin:0;
    font-size:1.4rem;
    padding:0;
    padding-left:5%;
 
    padding-right:5%;
    
    
   }
.marquee-image4 {
    width: 80%; /* Set a responsive width based on the container size */
    max-width: 15rem; /* Limit the maximum width to prevent it from growing too large */

}


/* -------- PARTIE AVEC FORMATION EN DESSOUS PHOTO MOI -------- */
.formations{
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: rgba(0, 0, 0, 0.781);
    font-size:1.4rem;
    padding:0;
    margin:0;
    height:34%;
    width:100%;
    user-select: none;
    transition:scale 1s ease-out;
    margin-bottom:8rem;
    margin-top:1.5rem;
    
} 

/* "FORMATIONS */
.marquee-itemform{
    transition:scale 1s ease-out;
    width:45%;
    margin:0;

    padding-left:2rem;
    padding-right:2rem;
    font-size:1.6rem;
    
}

.marquee-image3{
    width:11rem;
}

/* --- HOVER FORMATION */

/* Scale qui augmente quand on hover l'image */
.marquee-item3:hover{
    scale:1.19;
    transition:scale .25s;
    cursor: default;
    
}

.marquee-item4:hover{
    scale:1.19;
    transition:scale .25s;
    cursor: default;
    
}

/* Hover l'image fait apparaître le texte */
.hoverabout:hover{
    opacity:1;
    

}

/* Formatage contenu texte formations (diplôme, option son...)*/
.formation_content{
    opacity:0;
    position:absolute;
    color: white;
    text-shadow: 0px 0px 6px rgb(0, 0, 0);
    text-align: center;
    justify-content: center;
    font-size:1rem;
    width:100%;

}

.marquee-item3:hover .formation_content{
    opacity:1;
}

/* anim retour */
.marquee-item3{
    transition:scale 1s ease-out; }

.marquee-item4:hover .formation_content{
    opacity:1;
}

/* anim retour */
.marquee-item4{
    transition:scale 1s ease-out;
    padding-left:1rem;
     }


/* --- NOUVELLE PARTIE CERTIFICATION --- */

/* --- ANIM TEXTE SOURIS --- */
.hover-1 {
    background-image: linear-gradient(rgba(199, 199, 199, 0.178),rgba(148, 148, 148, 0.178));
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: .4s;
  }
  .hover-1:hover {
    background-size: 100% 100%;
  }





  .marquee-item3::after {
    content: attr(data-software); /* Gets the name of the software from the data-software attribute */
    position: absolute;
    display:flex;
    margin-top:3%;
    justify-content:center;
    align-items:center;
    background-color: rgba(0, 0, 0, 0.8); /* Background for better readability */
    color: white;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease; /* Only opacity transition */
    pointer-events: none; /* Prevents interaction with the text */
}

.marquee-item3:hover::after {
    opacity: 1; /* Only fade in the tooltip on hover */
}

  
.marquee-item4::after {
    
    position: absolute;
    bottom: -2.2rem; /* Adjust this value to control vertical placement */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8); /* Background for better readability */
    color: white;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none; /* Prevents interaction with the text */
  }
  
  .marquee-item4:hover::after {
    opacity: 1;
    transform: translateX(-50%) translateY(-0.2rem); /* Slight animation effect on hover */
  }

 /* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
 @media screen and (max-width: 1200px) {
    .about__content p{
        margin:0;
        padding:0%;
        font-size:1.1rem;
    }
    .about__me-image{
        max-width:18rem;
    }
    .marquee-image4 {
        width: 92%; /* Set a responsive width based on the container size */
    
    }
    .logiciels{
        width:80%;
        height:60%;
    }
 }


 @media screen and (max-width: 1024px) {

    .marquee-image3{
        width:4rem;
    }
    .marquee-itemform, .marquee-itemform2{
        font-size:1rem;
    }

    .formation_content{
        font-size:.6rem;
    }

    .about__me-image{
        max-width:15rem;
    }

    .logiciels{
        width:40%;
        background-color:rgba(0, 0, 0, 0.712);
        padding:0px;
        border-radius: 10rem;
    }
    .marquee-image4 {
        width: 92%; /* Set a responsive width based on the container size */
        max-width: 15rem; /* Limit the maximum width to prevent it from growing too large */
    
    }

    .formations{
        font-size:2rem;
        padding:0;
        margin:0;
        width:70%;
        height:55%; 
        margin-top:2rem;
        
    } 
    .about__container{
        grid-template-columns: 1fr;
    }
    .about__me{
        padding-top:0rem;
        
    }

    .about__content p{
        margin:0;
        padding:0%;
        font-size:.8rem;
        letter-spacing: -.5px;
    }
    .logiciels{
        margin-top:2rem;
        padding:0;
    }

    
    
    /* Mise en forme de "Logiciels" */
    .marquee-itemform2{
        transition:scale 1s ease-out;
        padding-left:1rem;
        padding-right:1rem;
       }
    
    .marquee-image4{
        padding:12%;}
    


    .about__me-image{
        width:22rem;
    }


}



  /* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
  @media screen and (max-width: 600px) {

    .marquee-image3{
        width:4rem;
    }
    .marquee-itemform, .marquee-itemform2{
        font-size:1rem;
    }

    .formation_content{
        font-size:.6rem;
    }

    .about__me-image{
        width:15rem;
    }

    .logiciels{
        width:85%;
        background-color:rgba(0, 0, 0, 0.712);
        padding:0px;
        border-radius: 10rem;
    }
    .marquee-image4 {
        width: 92%; /* Set a responsive width based on the container size */
        max-width: 15rem; /* Limit the maximum width to prevent it from growing too large */
    
    }

    .formations{
        display:flex;
        justify-content:center;
        align-items:center;
        background-color: rgba(0, 0, 0, 0.781);
        font-size:1.4rem;
        padding:0;
        margin:0;
        margin-right:5rem;
        margin-left:3rem;
        width:100%;
        height:55%;
        user-select: none;
        transition:scale 1s ease-out;
        margin-top:1.5rem;
        
    } 
    .about__content{
        background-color:rgba(0, 0, 0, 0.082);
        color: whitesmoke;
        padding-left:.8rem;
        padding-right:.8rem;
        font-size:1rem;
        word-spacing:0.3rem;  
        padding-bottom:2rem;
        text-align: justify;
        text-justify: inter-word;   
        margin-top:2rem;
        
    }

    .about__container {

        background-size: auto;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;

    }
    

    
  .marquee-item3::after {
    margin-top: 0%;
    padding: 0.1rem 0.5rem;
    font-size: 0.5rem;

}

}   