/* Bounce animation for fading in */
@keyframes bounceFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8); /* Start slightly smaller */
  }
  60% {
    opacity: 1;
    transform: scale(1.1); /* Slightly grow the element */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Set to normal size */
  }
}

/* Bounce animation for fading out */
@keyframes bounceFadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05); /* Grow the indicator */
  }
  100% {
    opacity: 0;
    transform: scale(0.8); /* Shrink the indicator */
  }
}

.now-playing-container {
  position: relative;
  z-index: 1000;
}

.now-playing-indicator {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 1.1rem;
  z-index: 1000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  opacity: 1;
  transform: scale(1);
  animation: none; /* No animation initially */
}

/* Apply fade-in animation when the indicator is displayed */
.now-playing-indicator.fade-in {
  animation: bounceFadeIn 0.25s ease-out forwards;
}

/* Apply fade-out animation when the indicator is hidden */
.now-playing-indicator.fade-out {
  animation: bounceFadeOut 0.25s ease-out forwards;
}
    

@media screen and (max-width: 600px) {

  .now-playing-indicator {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 6px 8px;
    font-size: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    opacity: .5;
    transform: scale(1);
    animation: none; /* No animation initially */
  }
}