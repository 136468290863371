@font-face {
   font-family: "Lovelo Black";
   src: url("../src/assets/fonts/Lovelo Black.otf") 
}
  
@font-face {
   font-family: "Lovelo Light";
   src: url("../src/assets/fonts/Lovelo Line Light.otf") 
}

@font-face {
   font-family: "Organetto Light";
   src: url("../src/assets/fonts/Organetto Light.otf") 
}

@font-face {
   font-family: "Organetto Thin";
   src: url("../src/assets/fonts/Organetto Thin.otf") 
}



*{
    margin:0;
    padding:0;
    border:0;
    outline:0;
    box-sizing:border-box;
    list-style:none;
    text-decoration:none;
    cursor:small;
    max-width: 100%;
    box-sizing: border-box;
}

:root{
    --color-bg:#2a4021;
    --color-bg-variant:#2c6c37;
    --color-primary:#68d57a;
    --color-primary-variant:rgba(127, 255, 77, 0.4);
    --color-white:#fff;
    --color-light:rgba(255,255,255,0.6);

    --transition: all 400ms ease;

    --container-width-lg:100%;
    --container-width-md:100%;
    --container-width-ms:70%;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display:none;
}



body {
    font-family: 'Oswald', sans-serif;
    background: rgba(42, 64, 33, 1) url('../src/assets/background_new.webp') center center / cover no-repeat fixed;
    color: var(--color-white);
    line-height: 1.7;
}


body::before {
   content: '';
   position: fixed; /* Use fixed instead of absolute */
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5); /* Adjust this opacity */
   z-index: -1; /* Ensure it's below content */
   pointer-events: none;
}
/* ============= GENERAL STYLES =========== */

 .container{
    width: var(--container-width-lg);
    margin:0 auto;
 }

 h1,
 h2,
 h3,
 h4,
 h5{
    font-weight: 500;
 }



 section > h2,
 section > h5 {
    text-align: center;
    color: var(--color-light);
 }

 section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
 }

 .text-light{
    color:var(--color-light);
 }

 a{
   color:var(--color-primary);
 }


 .whitea{
   color:var(--color-primary);
 }

 a:hover{
    color: var(--color-primary);
 }

 .btn{
    width:max-content;
    display:inline-block;
    color:lightgrey;
    padding:0.75rem 1.2rem;
    border-radius:0.4rem;
    cursor:pointer;
    border:1x solid var(--color-primary);
    transition: var(--transition);
 }


 .btn:hover{
    background: rgba(0, 0, 0, 0.85);
    color: rgb(247, 247, 247);
 }

 .btn-primary {
    background:rgba(0, 0, 0, 0.73);
    color:rgb(189, 189, 189);
 }

 img{
    display: block;
    width:100%;
    object-fit: cover;
 }


 /* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
 @media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
 }

  /* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
  @media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2{
        margin-top: 2rem;
    }
 }