#services {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-image: url(../../../src/assets/background_competences2.webp);
    min-height: 100vh;
    height:auto;
    margin-top: 0;
    padding-bottom: 5rem;
    padding-top: 3rem;
    z-index:0;
    position: relative; /* Ensure the ::after pseudo-element is positioned correctly */
}

/* Adding the opacity filter with an ::after pseudo-element */
#services::after {
    content: ''; /* Empty content for the pseudo-element */
    position: absolute; /* Absolute positioning to cover the entire section */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: -1; /* Position it behind the content */
}


.services__container{
    display:grid;
    grid-template-columns: repeat(4,1fr);
    padding-left:15%;
    padding-right:15%;
    gap:1.5rem;
    
    
    
    
}

.service1,
.service2{
    background-position: center center;
    transition:var(--transition);
    overflow: hidden;
    -webkit-transition: background-size 4s ease-out;
    -moz-transition: background-size 4s ease-out;
    -o-transition: background-size 4s ease-out;
    transition: background-size 4s ease-out;
    background-size:50rem;  
    max-width: 50rem;
    display:flex;
    padding-top:1rem;
    
    min-height:28rem;
}

.service3,
.service4{
    background-position: center center;
    transition:var(--transition);
    overflow: hidden;
    -webkit-transition: background-size 4s ease-out;
    -moz-transition: background-size 4s ease-out;
    -o-transition: background-size 4s ease-out;
    transition: background-size 4s ease-out;
    background-size:56rem;  
    max-width: 50rem;
    min-height:28rem;
    display:flex;
    padding-top:1rem;
}

.service1{
    background-image:url(../../../src/assets/backgroundTV.webp); }
.service2{
    background-image:url(../../../src/assets/backgroundCINE.webp); }
.service3{
    background-image:url(../../../src/assets/backgroundJV.webp); }
.service4{
    background-image:url(../../../src/assets/backgroundZIK.webp); }

.service1:hover,
.service2:hover,
.service3:hover,
.service4:hover{
    cursor: default;
    background-size:80rem;
    -webkit-transition: background-size 20s ease-out;
    -moz-transition: background-size 20s ease-out;
    -o-transition: background-size 20s ease-out;
    transition: background-size 20s ease-out;
    user-select: none;
    
}

.service:hover img {
    transform: scale(1.1);
    box-shadow: 5rem 5rem 4rem 4rem black;
    

}
.service__content h3{
    color:white;
    font-size:2.2rem;
    font-family:Organetto Thin, Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    align-items: center;  
    text-shadow: 2px 2px 3px rgb(0, 0, 0);

}

.service__content{
    display: flex;
flex-direction: column;
}
.service__content h2{
    color:white;
    font-family: 'Lovelo';
    font-size:1rem;
    text-align: center;
    line-height:1.5;
    text-shadow: 2px 2px 3px rgb(0, 0, 0);
    letter-spacing: .25em;
}

.service__content p{
    font-family: 'Oswald';
    font-weight: 300;
    font-size:1.25rem;
    text-align:justify;
    justify-content: center;
    padding-left:2rem;
    padding-right:2rem;
    opacity: .75;
    

}

.service__content:hover.service__content p{
    opacity:1;
    /*font-size:1.4rem;*/
    transition: opacity .5s ease-in-out;
    
}
.service__content:hover .bandeau{
    opacity:1;
    
    transition: opacity .25s ease-in-out;
}
.service__content:hover .bandeautv{
    opacity:1;
    
    transition: opacity .25s ease-in-out;
}


.service__titre{
    -webkit-transition: transform .15s ease-in-out;
    -moz-transition: transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out;
    transition: transform .5s  cubic-bezier(0.1, 0.05, 0.5, 0.9);
    margin-top:4rem;
    margin-bottom:10%;


}

.service__content:hover .service__titre{
    transform: translateY(-25%);
    transition: transform .5s ease-out;
}
/* "Secteurs d'activité" */
.h2services{
    font-size:1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:2rem;
    flex-wrap: nowrap;
    color:rgba(156, 228, 156, 0.918);

}
.h4services{
    font-size:2.5rem;
    padding:.5em;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}

/* Section au dessus avec compétences et secteur activité */
.titreservices{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}


/* --- NOUVELLE PARTIE CERTIFICATION --- */
.conteneurbandeau{
    display: flex;
    flex-direction: column;
    margin-top: auto; /* Push it to the bottom */
}
.conteneurbandeau p{
    margin-bottom:10%;
}
.bandeau{
    background: rgb(0,0,0);
background: radial-gradient(circle, rgba(0,0,0,0.7) 0%, rgba(1,1,1,0.48) 27%, rgba(173,173,173,0.24) 49%, rgba(0,0,0,0.48) 62%, rgba(0,0,0,0.29735644257703087) 75%, rgba(0,0,0,0.7) 100%);
    padding-top:1%;
    padding-bottom:1%;
    justify-content: center;
    border-radius: 10rem;
    opacity: 0.2;
    transition: opacity .5s ease-in-out;
    animation: radiuscolor 1s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}
.marquee-imagecertif1{
    width:4.8rem;
}
.marquee-imagecertif{
    width:6rem;
}
.bandeautv{
    background: rgb(0,0,0);
background: radial-gradient(circle, rgba(0,0,0,0.7) 0%, rgba(1,1,1,0.48) 27%, rgba(173,173,173,0.24) 49%, rgba(0,0,0,0.48) 62%, rgba(0,0,0,0.29735644257703087) 75%, rgba(0,0,0,0.7) 100%);
    padding-top:1%;
    padding-bottom:1%;
    border-radius: 10rem;
    opacity: 0.2;
    transition: opacity .5s ease-in-out;
    animation: radiuscolortv 1s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes radiuscolortv {
    from {background-color:rgba(255, 63, 63, 0.171);}
    to {background-color: rgba(0, 0, 0, 0.616);}
  }
  @keyframes radiuscolor {
    from {background-color:rgba(63, 255, 111, 0.171);}
    to {background-color: rgba(0, 0, 0, 0.616);}
  }


 /* ============= MEDIA QUERIES (MEDIUM DEVICES) ==================== */
 @media screen and (max-width:1200px){
    .services__container{
        grid-template-columns: 1fr 1fr;
        gap:0rem;
        padding:0;
    }

}
  /* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
  @media screen and (max-width: 600px) {
    .services__container{
        grid-template-columns: 1fr 1fr;
        gap:0rem;
        padding:0;
    }

    .service__content p{
        font-size:.8rem;
        padding-left:1rem;
        padding-right:1rem;
        
    
    }
    .h2services{
        font-size:.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom:2rem;
        flex-wrap: nowrap;
        color:rgba(156, 228, 156, 0.918);
    
    }
    .h4services{
        font-size:1.3rem;
        padding:.5em;
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
        
        
    }
    #services {
        padding-bottom: 0rem;
        padding-top: 1rem;
    }
    #services {
        background-size: auto;
    }

    .service__content h3{
        font-size:1.2rem;
    
    }
    
    .service__content h2{
        font-size:.5rem;

    }
    
    .service__content p{
        font-size:.8rem;
    
    }
    .conteneurbandeau p{
        margin-bottom:0%;
    }
    .bandeau{
        padding-top:0%;
        padding-bottom:0%;
    }

    .service__titre{
        margin-top:1rem;
        margin-bottom:0;
    }
    .service1, .service2, .service3, .service4 {
        height: 15rem; /* Adjust height as needed */
        min-height: unset; /* Remove the default min-height */
    }
    #services {
        min-height: 80vh;
        height:auto;
    }

    .marquee-imagecertif1{
        width:4rem;
    }
    .marquee-imagecertif{
        width:5rem;
    }
}