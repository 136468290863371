footer {
    position: relative;
    text-align: center;
    font-size: 0.9rem;
    z-index:0;
    height:22vh;    
}

footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height:100%;
    height:auto;
    background: rgba(0, 0, 0, 0.705);
    z-index: -1; /* Send it behind the content */
}

footer a{
    color: whitesmoke;
}

.footer__logo{
    font-size:2rem;
    font-weight: 500;
    display:inline-block;

}

.permalinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:2rem;
    margin:0 auto 3rem;
}

.footer__copyright{
    margin-bottom:4rem;
    color:whitesmoke;
}


  /* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
  @media screen and (max-width: 600px) {
    .footer__logo{
        font-size:1.5rem;
        margin-bottom:1rem;
        margin-top:1rem;
    }
    footer {
        font-size: 0.75rem;
    }
}