.project-details {
  position: relative; /* Required to position the overlay pseudo-element */
  color: #fff;
  padding: 2rem;
  margin-left: 4rem;
  margin-right: 4rem;
  border-radius: 5rem;
  overflow-y: auto; /* Allow scroll if necessary */  
  background-image: url(../../../src/assets/background_forest.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  z-index: 0;
}

.project-details::before {
  content: ''; /* Empty content for the pseudo-element */
  position: absolute;
  height: 100%;
  width:100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #2c2c2cd5, #30462b8e);
  border-radius: 5rem; /* Match the border radius of the parent */
  z-index: -1; /* Place the overlay behind the content */
}

.centered-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.centered-title:hover{
  
  margin-bottom: 1rem;
  font-size:2.15rem;
}

.project-main {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.project-text {
  flex: 1;
  font-size: 1.15rem;
  margin:0rem;
}

.project-text h2 {
  font-size: 1.5rem;
  color: #52b04f;
  margin-bottom: 1rem;
}

.project-text p {
  margin-bottom: 0.5rem;
  margin-top:2rem;
  margin-bottom:2rem;
  text-align: justify;
}

.project-image {
  flex: 1.6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 1rem;
  color: #fff;
  font-size: 2rem;
  padding: 0.5rem;
  margin: 3rem;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-button.prev {
  left: 0.1rem;
}

.carousel-button.next {
  right: 0.1rem;
}

/* Uniformisation des dimensions pour les vidéos, iframes et images principales */
.main-image,
.main-video,
iframe {
  width: 100%; /* Utilisation de width: 100% pour s'adapter au conteneur */
  height: 435px; /* Hauteur uniforme correspondant à celle de l'iframe YouTube */

  object-fit: cover; /* Assure que l'image ou la vidéo remplisse bien le conteneur sans déformation */
  border-radius: 3rem;
}

.main-image.visible {
  display: block;
  opacity: 1;
}

.project-additional-images {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.project-additional-images img,
.project-additional-images video {
  width: 15rem; /* Largeur fixe pour les images et vidéos supplémentaires */
  height: 100px; /* Hauteur fixe pour les miniatures */
  object-fit: cover; /* Pour s'assurer que l'image ou la vidéo reste bien proportionnée */
  cursor: pointer;
  border-radius: 1.25rem;
}



/* Bouton retour */
.back-button {

  font-family: 'Oswald', sans-serif;
  display: block; /* Center the button */
  margin: 2rem auto; /* Center the button horizontally with space at the top */
  font-size: 1rem;
  color: #fff;
  background-color: #334722;
  width: 75rem;
  height: 3.5rem;
  border: none;
  border-radius: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: visible;
  position: relative; /* Position relative for the pseudo-element */
  z-index: 1; /* Ensure the button content stays above the overlay */
  
  background-image: url(../../../src/assets/background_forest.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.back-button::before {
  content: ''; /* Empty content for the pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 22, 21,0.8); /* Semi-transparent black overlay */
  border-radius: 1rem; /* Match the border-radius of the button */
  z-index: -1; /* Place the overlay behind the button content */
}

.back-button:hover {
  scale:1.05;
}


  .project-description p {
    font-family: 'Poppins', sans-serif; /* Changer la police */
    font-size: 16px;                  /* Changer la taille de la police */
    line-height: 1.53;                 /* Espacement entre les lignes */
    color: #ffffff;                      /* Couleur du texte */
    
  }

  /* ============= MEDIA QUERIES (SMALL DEVICES) ==================== */
  @media screen and (max-width: 600px) {

    .project-details {
      position: relative; /* Required to position the overlay pseudo-element */
      color: #fff;
      margin-left:.75rem;
      margin:0;
      border-radius: 3rem;

    }
    .project-details::before {
      border-radius: 1rem; /* Match the border radius of the parent */
    }

    .main-image,
    .main-video,
    iframe {
      width: 100%; /* Utilisation de width: 100% pour s'adapter au conteneur */
      height: 100%; /* Hauteur uniforme correspondant à celle de l'iframe YouTube */
      border-radius: 1.5rem;
      min-width:21rem;
      min-height:12rem;
    }

    .project-additional-images img,
.project-additional-images video {
  width: 7rem; /* Largeur fixe pour les images et vidéos supplémentaires */
  height: 100%; /* Hauteur fixe pour les miniatures */
  border-radius: 1.25rem;
}
    .project-image {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .project-main {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    
.centered-title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 0rem;
  color: #ffffff;
}



.project-text {
  flex: 1;
  font-size: 1.05rem;
  margin-bottom: 0rem;
}

.project-text h2 {
  font-size: 1.3rem;
  margin-bottom:0rem;
}

.project-details {
  background-size:110rem;
}

.carousel-button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.5rem;
  padding: .25rem;
  margin: .25rem;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}
.carousel-button.prev {
  left: 0rem;
}

.carousel-button.next {
  right: 0rem;
}
.project-image {
  flex: 1.6;
  display: flex;
  flex-direction: column;
  align-items: center;
}


  }